<template>
  <div class="">
    <div
      class="modal fade"
      id="progress-tab-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="progress-tab-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-bold" id="exampleModalLabel">
              Progress Log
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="showLoader" class="d-flex justify-content-center">
              <div class="spinner-border spinner-border-sm" role="status"></div>
            </div>
            <div v-else>
              <h5 class="fw-bold">
                {{ student.last_name }}, {{ student.first_name }}
                {{ student.middle_name }}
              </h5>
              <span
                >{{ student.id }} | {{ student.abbrev }} |
                {{ student.component }}</span
              >
              <ul class="timeline mx-2">
                <li v-for="log in logs" :key="log.id">
                  <p class="mb-0">{{ log.description }}</p>
                  <span
                    class="text-muted form-text mt-0"
                    style="font-size: 12.5px"
                  >
                    By: {{ log.updated_by }} -
                    {{ log.created_at | completeDateTime }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressLogTab',
  /*  props: {
    student: {
      required: true,
      type: Object,
    },
  }, */
  data() {
    return {
      logs: [],
      showLoader: false,
      student: {},
    };
  },
  methods: {
    getStudentProgressLogs(student) {
      $('#progress-tab-modal').modal('show');
      this.logs = [];
      this.student = student;
      this.showLoader = true;
      axios.get('api/nstp-status-logs/' + student.id).then((res) => {
        this.logs = res.data;
        this.showLoader = false;
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #bcc0c5;
  display: inline-block;
  position: absolute;
  left: 7px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 0px;
}
ul.timeline > li:before {
  content: '';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #bcc0c5;
  left: 0px;
  width: 15px;
  height: 15px;
  z-index: 400;
}
</style>
