<template>
  <div>
    <div>
      <div
        class="modal"
        data-backdrop="static"
        id="nstp-student-information-modal"
      >
        <div class="modal-dialog rounded" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="d-flex justify-content-between mb-3">
                <div class="my-auto">
                  <div>
                    <h5 class="font-weight-bold mb-1">
                      {{ student.last_name }}, {{ student.first_name }}
                      {{ student.middle_name }}
                      {{ student.extension_name }}
                    </h5>
                    <span class="" v-if="showSerialNumber"
                      >{{ student.serial_number }} |
                      {{ student.issued_at | completeDateTime }}</span
                    >
                    <p class="">{{ student.abbrev }}</p>
                  </div>
                </div>
                <i class="bi bi-person-circle" style="font-size: 50px"></i>
              </div>

              <div class="">
                <div class="form-group" v-if="editMode">
                  <label for="" class="">Name</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="student.last_name"
                      placeholder="Last Name"
                      :disabled="!editMode"
                    />
                    <input
                      type="text"
                      class="form-control"
                      v-model="student.first_name"
                      placeholder="First Name"
                      :disabled="!editMode"
                    />
                    <input
                      type="text"
                      class="form-control"
                      v-model="student.middle_name"
                      placeholder="Middle Name"
                      :disabled="!editMode"
                    />
                    <input
                      type="text"
                      class="form-control"
                      v-model="student.extension_name"
                      placeholder="Ext."
                      :disabled="!editMode"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="" class="">Component</label>
                      <select
                        class="form-control"
                        v-model="student.component"
                        :disabled="!editMode"
                      >
                        <option
                          v-for="component in components"
                          :key="component"
                        >
                          {{ component }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="" class="">Award Year</label>
                      <select
                        class="form-control"
                        v-model="student.award_year"
                        :disabled="!editMode"
                      >
                        <option
                          v-for="(awardYear, index) in awardYears"
                          :key="index"
                        >
                          {{ awardYear }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="" class="form-label">Program</label>
                  <input
                    type="text"
                    name=""
                    class="form-control"
                    id=""
                    v-model="student.program_name"
                    required
                    disabled
                  />
                </div>

                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="" class="form-label">Birthdate</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="student.birthdate"
                        :disabled="!editMode"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="" class="form-label">Sex</label>
                      <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="student.sex"
                        :disabled="!editMode"
                      >
                        <option value="MALE">MALE</option>
                        <option value="FEMALE">FEMALE</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="" class="form-label">Email</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="student.email"
                        :disabled="!editMode"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="" class="form-label">Contact No</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="student.contact_no"
                        :disabled="!editMode"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="" class="form-label">Street/Brgy.</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="student.street_or_brgy"
                    :disabled="!editMode"
                  />
                </div>

                <div class="mb-3">
                  <label for="" class="form-label">Town/City</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="student.town_or_city"
                    :disabled="!editMode"
                  />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Province</label>
                  <select
                    name=""
                    class="form-control"
                    v-model="student.province"
                    id=""
                    :disabled="!editMode"
                  >
                    <option value="AGUSAN DEL NORTE">AGUSAN DEL NORTE</option>
                    <option value="AGUSAN DEL SUR">AGUSAN DEL SUR</option>
                    <option value="SURIGAO DEL NORTE">SURIGAO DEL NORTE</option>
                    <option value="SURIGAO DEL SUR">SURIGAO DEL SUR</option>
                    <option value="DINAGAT ISLANDS">DINAGAT ISLANDS</option>
                  </select>
                </div>

                <div class="my-4" v-if="editable">
                  <button
                    type="submit"
                    class="btn"
                    :class="{
                      'btn-outline-secondary text-dark': !editMode,
                      'btn-success': editMode,
                    }"
                    @click.prevent="saveOrEdit"
                    :disabled="updating"
                  >
                    <i class="bi bi-pencil-square mr-1"></i>
                    {{ editMode ? 'Save changes' : 'Edit' }}
                  </button>

                  <span
                    class="spinner-border spinner-border-sm mx-3"
                    v-if="updating"
                  ></span>
                </div>
              </div>

              <div>
                <p class="small text-muted mb-0">
                  Date Encoded: {{ student.created_at | completeDateTime }}
                </p>
                <p class="small text-muted">
                  Last updated by: {{ student.updated_by }}
                </p>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudentInformationModal',
  props: {
    showSerialNumber: {
      required: true,
      type: Boolean,
    },
    components: {
      required: true,
      type: Array,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    awardYears: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      updating: false,
      loader: false,
      editMode: false,

      student: new Form({
        id: '',
        abbrev: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        extension_name: '',
        hei_name: '',
        component: '',
        award_year: '',
        sex: '',
        birthdate: '',
        program_name: '',
        program_id: '',
        email: '',
        contact_no: '',
        street_or_brgy: '',
        town_or_city: '',
        province: '',
        created_at: '',
        updated_by: '',
        serial_number: '',
        issued_by: '',
        issued_at: '',
        issued_by: '',
      }),
    };
  },
  methods: {
    open(student) {
      $('#nstp-student-information-modal').modal('show');
      this.student.fill(student);
    },
    hide() {
      $('#nstp-student-information-modal').modal('hide');
    },

    saveOrEdit() {
      this.editMode ? this.saveChanges() : (this.editMode = !this.editMode);
    },

    saveChanges() {
      this.updating = !this.updating;
      axios
        .put('api/nstp/' + this.student.id, this.student)
        .then((res) => {
          this.updating = !this.updating;
          this.$emit('getApplications');
          this.toggleEditing();
        })
        .catch((error) => {
          this.updating = !this.updating;
          this.toggleEditing();
        });
    },

    toggleEditing() {
      this.editMode = !this.editMode;
    },
  },
};
</script>
