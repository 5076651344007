<template>
  <div>
    <h5 class="my-auto font-weight-bold">
      NSTP PORTAL
    </h5>
    <div class="card rounded mt-4 shadow-sm">
      <div class="card-body">
        <ul class="nav nav-tabs" id="nstp-tabs">
          <li class="nav-item ">
            <a
              class="nav-link active"
              id="Applications"
              @click.prevent="tabActive('Applications')"
              href="#"
              >Applications</a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="Masterlist"
              @click.prevent="tabActive('Masterlist')"
              href="#"
              >Masterlist</a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="Coordinators"
              href="#"
              @click.prevent="tabActive('Coordinators')"
              >Coordinators</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Reports"
              href="#"
              @click.prevent="tabActive('Reports')"
              >Reports</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="">
      <component
        v-bind:is="currentTab"
        :awardYears="awardYears"
        :statuses="statuses"
        :components="components"
      >
      </component>
    </div>
  </div>
</template>

<script>
import Applications from './components/Applications.vue';
import Masterlist from './components/Masterlist.vue';
import Coordinators from './components/Coordinators.vue';
import Reports from './components/Reports.vue';
import { statuses, awardYears, components } from '../../../js/award-years.js';

export default {
  name: 'Nstp',
  components: {
    Applications,
    Masterlist,
    Coordinators,
    Reports,
  },
  data() {
    return {
      currentTab: 'Applications',
      awardYears: awardYears,
      statuses: statuses,
      components: components,
    };
  },
  methods: {
    tabActive(element) {
      this.currentTab = element;
      $('#nstp-tabs .nav-link').removeClass('active');
      $('#' + element).addClass('active');
    },
  },
};
</script>
