<template>
  <div>
    <div class="card rounded shadow-sm">
      <div class="card-body">
        <div>
          <input
            type="search"
            placeholder="Try searching the last name or HEI abbrev"
            class="form-control col-3"
            v-model="input"
          />
        </div>
        <table class="table table-bordered table-hover table-sm mt-3">
          <thead>
            <th>#</th>
            <th>HEI</th>
            <th>LAST NAME</th>
            <th>FIRST NAME</th>
            <th>MIDDLE NAME</th>
            <th>EXT.</th>
            <th>EMAIL</th>
            <th>CONTACT NO</th>
            <th>CREATED AT</th>
          </thead>
          <tbody>
            <tr class="text-center" v-if="tableLoader">
              <td colspan="9">
                <div class="spinner-border spinner-border-sm mr-1"></div>
                Loading
              </td>
            </tr>
            <tr
              v-for="(coordinator, index) in filteredCoordinators"
              :key="coordinator.id"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ coordinator.abbrev }}</td>
              <td>{{ coordinator.last_name }}</td>
              <td>{{ coordinator.first_name }}</td>
              <td>{{ coordinator.middle_name }}</td>
              <td>{{ coordinator.ext_name }}</td>
              <td>{{ coordinator.email }}</td>
              <td>{{ coordinator.contact_no }}</td>
              <td>{{ coordinator.created_at | completeDateTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Coordinators',
  data() {
    return {
      coordinators: [],
      tableLoader: false,
      heis: [],
      input: '',
      updateMode: false,
      form: new Form({
        id: '',
        name_prefix: '',
        lastname: '',
        firstname: '',
        middlename: '',
        sex: '',
        email: '',
        contact_no: '',
        hei_code: '',
        titles: '',
      }),
    };
  },
  methods: {
    getCoordinators(options) {
      this.tableLoader = options.loader.enabled;
      axios
        .get('api/coordinators')
        .then((res) => {
          this.coordinators = res.data;
          this.tableLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
  },
  mounted() {
    this.getCoordinators({ loader: { enabled: true } });
  },

  computed: {
    filteredCoordinators() {
      let temp = this.coordinators;

      if (this.input != '') {
        temp = temp.filter((cav) => {
          return (
            cav['last_name'].toUpperCase().includes(this.input.toUpperCase()) ||
            cav['abbrev'].toUpperCase().includes(this.input.toUpperCase())
          );
        });
      }

      return temp;
    },
  },
};
</script>
