<template>
  <div>
    <div class="card rounded shadow-sm">
      <div class="card-body">
        <div>
          <button class="btn btn-primary mr-3" @click="generateSerialNumber">
            Generate serial number
            <span v-if="selected.length">({{ selected.length }})</span>
          </button>
          <button
            class="btn btn-outline-secondary text-dark"
            @click.prevent="
              getAllApplications({
                tableLoader: { isEnabled: false },
                reloadSpinner: { isEnabled: true },
              })
            "
          >
            Reload
          </button>
          <span
            class="spinner-border spinner-border-sm mx-3"
            v-if="reloadSpinner"
          ></span>
        </div>

        <div class="table-responsive">
          <table
            id="request-for-serial-table"
            class="table table-hover table-bordered table-sm mt-3"
          >
            <thead style="">
              <tr>
                <th>
                  <input
                    id="select-all-check"
                    type="checkbox"
                    v-model="checked"
                    @change="checked ? selectAll() : unselectAll()"
                    :disabled="!applications.length"
                  />
                </th>
                <th>ID</th>
                <th>NAME</th>
                <th>HEI</th>
                <th>COMPONENT</th>
                <th>A.Y.</th>
                <th>PROGRAM</th>
                <th>DATE ENCODED</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-if="!applications.length && !tableLoader"
              >
                <td colspan="13">
                  Hooray! You have no pending requests.
                </td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="13">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr
                v-for="app in applications"
                :key="app.id"
                @click="openStudentInformationModal(app)"
              >
                <td>
                  <input
                    type="checkbox"
                    :value="app"
                    v-model="selected"
                    @click.stop
                  />
                </td>

                <td>{{ app.id }}</td>
                <td>
                  {{
                    `${app.last_name}, ${app.first_name} ${app.middle_name} `
                  }}
                </td>
                <td>{{ app.abbrev }}</td>
                <td>{{ app.component }}</td>
                <td>{{ app.award_year }}</td>
                <td class="text-uppercase">{{ app.program_name }}</td>

                <td>
                  {{ app.updated_at | dateTime }}
                </td>
                <td>
                  <span class="badge badge-secondary">{{ app.status }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--  <UploadCsvModal
      ref="UploadCsvModal"
      v-on:parsed="displayResult"
    ></UploadCsvModal>
 -->
    <IssueSerialModal
      ref="IssueSerialModal"
      :selected="selected"
      @emptySelected="selected = []"
      @getAllApplications="
        getAllApplications({
          tableLoader: { isEnabled: false },
          reloadSpinner: { isEnabled: true },
        })
      "
    ></IssueSerialModal>

    <StudentInformationModal
      ref="StudentInformationModal"
      :showSerialNumber="false"
      :components="components"
      :awardYears="awardYears"
      :editable="true"
      @getApplications="
        getAllApplications({
          tableLoader: { isEnabled: false },
          reloadSpinner: { isEnabled: false },
        })
      "
    >
    </StudentInformationModal>
  </div>
</template>

<script>
import IssueSerialModal from './IssueSerialModal.vue';
import StudentInformationModal from '../../StudentInformationModal.vue';

export default {
  name: 'Applications',
  props: {
    awardYears: {
      required: true,
      type: Array,
    },
    components: {
      required: true,
      type: Array,
    },
  },
  components: {
    IssueSerialModal,
    StudentInformationModal,
  },
  data() {
    return {
      selected: [],
      checked: false,
      errors: [],
      applications: [],
      tableLoader: false,
      reloadSpinner: false,
    };
  },
  methods: {
    getAllApplications(config) {
      this.tableLoader = config.tableLoader.isEnabled;
      this.reloadSpinner = config.reloadSpinner.isEnabled;

      axios
        .get('api/nstp/admin/applications')
        .then((res) => {
          this.applications = res.data;
          this.tableLoader = this.reloadSpinner = false;
        })
        .catch((err) => {
          this.tableLoader = this.reloadSpinner = false;
        });
    },

    openStudentInformationModal(student) {
      this.$refs.StudentInformationModal.open(student);
    },

    generateSerialNumber() {
      if (!this.selected.length)
        return toastr.error('No student(s) selected.', 'Info');
      this.$refs.IssueSerialModal.open();
    },
    selectAll() {
      this.selected = [];
      this.applications.forEach((student) => {
        this.selected.push(student);
      });
    },
    unselectAll() {
      this.selected = [];
      this.applications.forEach((student) => {
        this.selected.pop(student);
      });
    },
  },
  mounted() {
    this.getAllApplications({
      tableLoader: { isEnabled: true },
      reloadSpinner: { isEnabled: false },
    });
  },
};
</script>

<style scoped>
#request-for-serial-table tr {
  cursor: pointer !important;
}
</style>
